import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/gardensuburbkitchen/nextjs/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/gardensuburbkitchen/nextjs/src/app/AwardsGrid.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/gardensuburbkitchen/nextjs/src/app/CateringSection.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/gardensuburbkitchen/nextjs/src/app/Contactform.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/gardensuburbkitchen/nextjs/src/app/Intro.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/gardensuburbkitchen/nextjs/src/app/Ourservices.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/gardensuburbkitchen/nextjs/src/app/Pressmedia.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/gardensuburbkitchen/nextjs/src/app/Slider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/gardensuburbkitchen/nextjs/src/components/Testimonials.jsx");
