"use client";
import Image from "next/image";
import Link from "next/link";

const Intro = () => {
  return (
    <div>
      <section className="bg-contain bg-no-repeat bg-bottom py-10 md:py-20 lg:py-24">
        <div className="max-w-screen-lg mx-5 lg:mx-auto">
          <div className="text-center mb-6 md:mb-8">
            <div className="text-base md:text-lg lg:text-xl uppercase text-colorBrown-600 font-semibold mb-6">
              Catering & Events
            </div>
            <h2 className="font-bold italic">
              Premium, Top-Tier Catering Services for Personal and Corporate
              Events
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-10 text-center text-white">
            {cards.map((card, index) => (
              <Link href={card.link} key={index}>
                <div className="group relative font-semibold p-6 mb-3 md:mb-6 lg:mb-10 overflow-hidden bg-gray-800 rounded-lg shadow-lg flex flex-col justify-end min-h-[500px] h-full">
                  {/* Background Image */}
                  <Image
                    src={card.image}
                    alt={card.title}
                    fill
                    className="absolute inset-0 object-cover z-0 transition-transform duration-700 ease-in-out group-hover:scale-110"
                    quality={75}
                    sizes="(max-width: 600px) 100vw, (min-width: 600px) 50vw"
                  />
                  {/* Overlay */}
                  <div className="absolute inset-0 bg-black opacity-25 z-5 transition-opacity duration-700 ease-in-out group-hover:opacity-50"></div>

                  {/* Description and Button (Hidden initially, move upwards on hover) */}
                  <div className="absolute -bottom-2 left-0 right-0 p-6 ">
                    {/* Title (Always Visible) */}
                    <h3 className="relative z-10 font-bold text-white mb-4 translate-y-32 transition-all duration-700 ease-in-out group-hover:opacity-100 group-hover:translate-y-[-40px]">
                      {card.title}
                    </h3>
                    <p className="text-white mb-4 opacity-0 translate-y-10 transition-all duration-700 ease-in-out group-hover:opacity-100 group-hover:translate-y-[-40%]">
                      {card.description}
                    </p>
                    <button className="px-6 py-2 text-sm font-bold uppercase tracking-wider bg-white text-black hover:bg-gray-300 focus:outline-none opacity-0 translate-y-10 transition-all duration-700 ease-in-out group-hover:opacity-100 group-hover:translate-y-[-20%]">
                      {card.buttonText}
                    </button>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Intro;

const cards = [
  {
    title: "Why Choose Us",
    description:
      "From romantic weddings to bespoke parties, Garden Suburb Kitchen has extensive experience in planning and preparing events to suit all tastes and budgets.",
    buttonText: "Find out why we're different",
    image: "/images/catering/experiences-bg.jpg",
    link: "/about",
  },
  {
    title: "Menu",
    description:
      "We’ve got a variety of menus to choose from and provide great inspiration and ideas to help you start planning your event.",
    buttonText: "Take a look at our menus",
    image: "/images/catering/about-bg.jpg",
    link: "/menu",
  },
  {
    title: "Weddings",
    description:
      "We’re always busy with something, so keep an eye out for our latest updates on what we’re doing and the events we’re part of.",
    buttonText: "Read all about it",
    image: "/images/catering/contact-bg.jpg",
    link: "/services/weddings",
  },
  {
    title: "Private Dining",
    description:
      "Whether it’s a private dinner party for friends and family or a special event you want to celebrate at home, we can help.",
    buttonText: "Start planning now",
    image: "/images/catering/bakeryt-bg.jpg",
    link: "/services/private-dining",
  },
  {
    title: "Corporate Catering",
    description:
      "If you’re looking for delicious food for an office party or delightful dishes to impress clients, look no further.",
    buttonText: "Find out why we're different",
    image: "/images/catering/media-bg.jpg",
    link: "/services/event-management",
  },
  {
    title: "Gallery",
    description:
      "Explore our gallery to get a taste of the food we offer and inspire your next event.",
    buttonText: "Take a look at our photos",
    image: "/images/catering/menus-bg.jpg",
    link: "/gallery",
  },
];
