"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "flowbite-react";

const Contactform = () => {
  return (
    <div>
      <section className="py-10 md:py-20 lg:py-24">
        <div className="max-w-screen-lg mx-5 lg:mx-auto">
          <div className="text-center mb-6 md:mb-8">
            <div className="text-base md:text-lg lg:text-xl uppercase font-medium text-gray-500 mb-6">
              Let Us Make Your Day A Special
            </div>
            <h2 className="font-semibold text-black italic">Make An Inquiry</h2>
          </div>
          <div className="grid grid-cols-1 gap-3 items-center justify-between mb-6 md:mb-10 ">
            <div>
              <form action="#" className="space-y-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    {/* <label for="first_name" className="block mb-2 text-sm font-medium text-gray-300 dark:text-white">First name</label> */}
                    <input
                      type="text"
                      id="first_name"
                      className="bg-gray-50 border-2 border-gray-300 text-gray-300 text-lg rounded-none focus:ring-blue-500 focus:border-blue-500 block w-full p-4"
                      placeholder="YOUR NAME"
                      required
                    />
                  </div>
                  <div>
                    {/* <label for="email" className="block mb-2 text-sm font-medium text-gray-300 dark:text-gray-300">Your email</label> */}
                    <input
                      type="email"
                      id="email"
                      className="shadow-sm bg-gray-50 border-2 border-gray-300 text-gray-300 text-lg rounded-none focus:ring-primary-500 focus:border-primary-500 block w-full p-4"
                      placeholder="YOUR E-MAIL"
                      required
                    />
                  </div>
                  <div>
                    {/* <label for="phone" className="block mb-2 text-sm font-medium text-gray-300 dark:text-gray-300">Phone Number</label> */}
                    <input
                      type="text"
                      id="phone"
                      className="block w-full text-lg text-gray-300 bg-gray-50 rounded-none border-2 border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 p-4"
                      placeholder="YOUR PHONE NO"
                      required
                    />
                  </div>
                  <div>
                    {/* <label for="subject" className="block mb-2 text-sm font-medium text-gray-300 dark:text-gray-300">Subject</label> */}
                    <input
                      type="text"
                      id="subject"
                      className="block w-full text-lg text-gray-300 bg-gray-50 rounded-none border-2 border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 p-4"
                      placeholder="NO OFF GUEST"
                      required
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  {/* <label for="message" className="block mb-2 text-sm font-medium text-gray-300 dark:text-gray-400">Your message</label> */}
                  <textarea
                    id="message"
                    rows="6"
                    className="block p-4 w-full text-lg text-gray-300 bg-gray-50 rounded-none shadow-sm border-2 border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                    placeholder="Leave a comment..."
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="bg-gray-700 text-white rounded-none px-8 py-3 lg:px-12 lg:py-4 uppercase text-center"
                >
                  Send message
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contactform;
