"use client";

import React from "react";
import Slider from "react-slick";
import Image from "next/image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonials = [
  {
    id: 1,
    name: "Dame Mary Berry",
    image: "/images/man.png",
    quote: "It does taste absolutely scrumptious",
  },
  {
    id: 2,
    name: "Monica Galetti",
    image: "/images/man.png",
    quote: "You created something stunning",
  },
  {
    id: 3,
    name: "Jane Dunn",
    image: "/images/man.png",
    quote: "It’s Genius",
  },
  {
    id: 4,
    name: "Regula Ysewijn",
    image: "/images/man.png",
    quote: "It’s like a walk in a rose garden on a crisp summer morning",
  },
  {
    id: 5,
    name: "Roger Pizey",
    image: "/images/man.png",
    quote: "It’s absolutely stunning, it’s beautiful",
  },
  {
    id: 6,
    name: "Rahul Mandal, Bake off winner",
    image: "/images/man.png",
    quote: "I can taste the falooda in the cake—that’s incredible",
  },
  {
    id: 7,
    name: "Matt Adlard",
    image: "/images/man.png",
    quote: "A party in your mouth",
  },
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
};

const TestimonialsCarousel = () => {
  return (
    <section className="relative py-16">
      {/* Background Image */}
      <div className="absolute inset-0 z-0">
        <Image
          src="/images/menu/elegant_brunch_table_cupcakes.jpeg" // Replace with the correct path to your background image
          alt="Background Image"
          fill
          className=" object-cover"
        />
      </div>

      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-65 z-10"></div>

      {/* Content */}
      <div className="relative max-w-screen-xl mx-auto px-5 lg:px-20 text-center z-20">
        <h2 className="text-4xl font-bold text-white mb-10">Testimonials</h2>

        <Slider {...settings}>
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className="p-5">
              <div className="bg-white shadow-lg rounded-lg p-8">
                <div className="flex items-center justify-center mb-4">
                  <Image
                    src={testimonial.image}
                    alt={testimonial.name}
                    width={80}
                    height={80}
                    className="rounded-full"
                  />
                </div>
                <p className="text-gray-600 text-xl mb-4">
                  "{testimonial.quote}"
                </p>
                <h3 className="font-bold text-2xl text-gray-800">
                  {testimonial.name}
                </h3>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default TestimonialsCarousel;
