import { useState } from "react";

const Card = ({ title, image }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 2 - 1;
    const y = ((e.clientY - top) / height) * 2 - 1;
    setMousePosition({ x, y });
  };

  const handleMouseLeave = () => {
    setMousePosition({ x: 0, y: 0 });
  };

  // Increased the multiplier to 25 for a more dramatic tilt effect
  const cardStyle = {
    transform: `rotateY(${mousePosition.x * 25}deg) rotateX(${
      mousePosition.y * -25
    }deg)`,
  };

  return (
    <div
      className="relative w-full h-[25rem] rounded-lg shadow-lg overflow-hidden transform transition-transform duration-500 cursor-pointer"
      style={cardStyle}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${image})` }}
      />
      <div className="absolute inset-0 bg-black bg-opacity-30 flex justify-center items-center transition-opacity duration-500 hover:opacity-0 top-0">
        <h3 className="text-white text-3xl font-bold text-center pt-64">
          {title}
        </h3>
      </div>
    </div>
  );
};

export default Card;
